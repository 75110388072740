<template>
  <b-row>
    <b-col md="7">
      <wallet-pie />
    </b-col>
    <b-col md="5">
      <config />
    </b-col>
    <b-col md="12">
      <timeline-card />
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { BRow, BCol } from 'bootstrap-vue'
import WalletPie from './wallet-pie.vue'
import Config from './config.vue'
import TimelineCard from './Timeline.vue'

export default {
  components: {
    BRow,
    BCol,
    Config,
    WalletPie,
    TimelineCard,
  },
  computed: {
    ...mapGetters({
      position: 'balancer/position',
      snap: 'balancer/snap',
    }),

  },
}
</script>
