<template>
  <b-card
    :title="$t('Portfolio history')"
  >
    <app-timeline v-if="snap.length">
      <app-timeline-item
        v-for="sna in snap"
        :key="sna.key"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6> {{ $t("Percentage to balance") }} ({{ sna.balanceWhen }}%) </h6>
          <h6> <span> {{ sna.timestamp }} </span> </h6>
        </div>
        <p>
          <b-List-group
            nav
            :lines="false"
          >
            <b-list-group-item
              v-for="(item, key) in sna.tokens"
              :key="key"
              :value="item.value"
            >
              <b-row>
                <b-col> {{ item.symbol }} - {{ item.token }} </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <strong> {{ $t('Percentage') }}: </strong> {{ item.percentage }}
                </b-col>
                <b-col>
                  <strong> {{ $t('Price') }}: </strong> ${{ item.price }}
                </b-col>
                <b-col>
                  <strong> {{ $t('Amount') }}:</strong> {{ item.amount }} {{ item.symbol }}
                </b-col>
                <b-col>
                  <strong> {{ $t('Value') }}: </strong> ${{ item.value }}
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-List-group>
        </p>
        <h5>{{ $t('Total') }} ${{ sna.total }} </h5>
      </app-timeline-item>
    </app-timeline>

    <app-timeline v-else>
      <app-timeline-item>
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6> {{ $t("No portfolio history") }} </h6>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BListGroup, BListGroupItem, BRow, BCol,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
  },
  computed: {
    ...mapGetters({
      snap: 'balancer/snap',
    }),
  },
}
</script>

<style>

</style>
