<template>
  <created v-if="isCreated" />
  <not-created
    v-else
    :title="$t('Create wallet balancer', { account: accountShortName })"
    :account="accountShortName"
  />
</template>

<script>
import { mapGetters } from 'vuex'
// does not remove, causes error in graphics
import EchartLine from '@/views/charts-and-maps/charts/echart/EchartLine.vue'
import Created from './created/index.vue'
import NotCreated from '../not-created/index.vue'

export default {
  components: {
    Created,
    NotCreated,
  },
  computed: {
    ...mapGetters({
      position: 'balancer/position',
      snap: 'balancer/snap',
      accountShortName: 'wallet/getAccountShortName',
    }),
    isCreated() {
      return this.position.isCreated
    },
  },
}
</script>
