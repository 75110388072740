<template>
  <b-card>
    <!-- confirm transaction -->
    <b-modal
      id="modal-processing"
      centered
      show
      size="sm"
      :title="title"
      hide-footer
      @hide="modalProcessingClose"
    >
      <slot>
        <b-card-text>
          <b-spinner
            style="width: 3rem; height: 3rem;"
            label="Large Spinner"
            type="grow"
          />
        </b-card-text>
      </slot>
      <template #modal-footer />
    </b-modal>

    <!-- edit -->
    <b-modal
      id="modal-edit-wallet"
      size="lg"
      hide-footer
      :title="$t('Edit tokens')"
    >
      <slot>
        <edit-tokens
          :title="labelAccount"
          :tokens-active="position.tokens"
        />
      </slot>
    </b-modal>

    <div class="card-title">
      {{ $t('Configuration') }}
    </div>

    <div>
      <div>
        <b-card-text class="mb-0">
          <strong> {{ $t("Automatic balancing") }} </strong>
        </b-card-text>
        <b-form-checkbox
          v-if="showCharts"
          :checked="position.isAutomated"
          class="custom-control-success"
          name="check-button"
          switch
          @input="changeIsAutomated"
        />
      </div>
      <div class="mt-1">
        <b-form-group
          label-for="percentage"
        >
          <strong> {{ $t("Percentage to balance") }} (%) </strong>
          <cleave
            id="percentage"
            v-model="balanceWhen"
            :value="position.balanceWhen"
            class="form-control"
            maxlength="2"
            :options="options.percentage"
            placeholder="%"
          />
        </b-form-group>

        <b-button
          v-ripple.400="'rgba(30, 30, 30, 0.15)'"
          size="sm"
          :disabled="canChangePercentage(position.balanceWhen)"
          variant="gradient-primary"
          @click="changePercentage()"
        >
          {{ $t("Save percentage") }}
        </b-button>

      </div>
      <div class="mt-1">
        <b-List-group
          nav
          :lines="false"
        >
          <b-list-group-item
            v-for="item in position.tokens"
            :key="item.value"
            :value="item.value"
          >
            <b-badge
              v-if="item.needApproval"
              variant="warning"
              class="badge-round cursor-pointer"
              @click="approve(item)"
            >
              {{ $t("Approve") }}
            </b-badge>

            {{ item.title }} <br> <strong> {{ item.percentage }}% </strong>
          </b-list-group-item>
        </b-List-group>

        <b-button
          v-ripple.400="'rgba(30, 30, 30, 0.15)'"
          size="sm"
          variant="gradient-primary mt-1"
          @click="editTokens()"
        >
          {{ $t("Edit tokens") }}
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BCard, BFormCheckbox, BCardText, BFormGroup, BButton, VBModal, BListGroup, BListGroupItem, BBadge, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import { ERC20Approve } from '@/services/token'
import { setAutomatic, setBalanceWhen } from '@/services/balancer'
import {
  changeGasIncrease, sleep,
} from '@/libs/helpers'

import { getPosition } from '@/services'
import EditTokens from '@/views/balancer/wallet/created/edit-tokens.vue'

export default {
  components: {
    BListGroup,
    BSpinner,
    BBadge,
    BListGroupItem,
    BCard,
    BButton,
    BCardText,
    BFormCheckbox,
    BFormGroup,
    Cleave,
    EditTokens,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      request: false,
      titleModal: this.$t('NotCreated.confirm transaction'),
      title: this.$t('Processing'),
      balanceWhen: 0,
      options: {
        percentage: {
          numericOnly: true,
          blocks: [2],
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      position: 'balancer/position',
      glassPosition: 'balancer/glassPosition',
      getAccount: 'wallet/getAccount',
      showCharts: 'balancer/showCharts',
    }),

    labelAccount() {
      return `${this.$t('Wallet')}: ${this.getAccount}`
    },
  },
  async created() {
    this.balanceWhen = (await getPosition(this.provider().getSigner(), this.getAccount)).balanceWhen
  },
  methods: {
    ...mapActions({
      setPosition: 'balancer/setPosition',
    }),

    toast(message, variant = 'warning', title = this.$t('NotCreated.wait')) {
      this.$bvToast.toast(message, {
        title,
        toaster: 'b-toaster-top-right',
        variant,
        solid: true,
        appendToast: true,
      })
    },

    canChangePercentage() {
      return [this.glassPosition.balanceWhen, 0, NaN].includes(parseFloat(this.balanceWhen))
    },

    async verifyTransaction(status) {
      switch (status) {
        case 0:
          this.toast(this.$t('your transaction failed, see more at'), 'warning', 'Failed')
          break
        default:
          this.toast(this.$t('successful transaction'), 'success', 'OK')
      }
    },

    async changeIsAutomated(value) {
      this.request = true

      try {
        this.$bvModal.show('modal-processing')
        const signer = this.provider().getSigner()
        const gasPrice = await changeGasIncrease(this.provider())
        const tx = await setAutomatic(signer, value, gasPrice)
        const result = await this.provider().waitForTransaction(tx.hash, 1, 1000 * 60)

        this.title = this.$t('confirming transaction, please wait a little longer')

        await this.verifyTransaction(result.status)
        await this.awaitForChange()
      } catch (e) {
        if (['ACTION_REJECTED'].includes(e.code)) {
          this.toast(this.$t('operation canceled by user'), 'warning', 'Failed')
        } else {
          this.toast(this.$t('your transaction failed, see more at metamask'), 'warning', 'Failed')
        }
        this.setPosition({ ...this.position })
      }
      this.request = false
      this.$bvModal.hide('modal-processing')
      this.title = this.$t('Processing')
    },

    async changePercentage() {
      this.request = true

      try {
        this.$bvModal.show('modal-processing')
        const signer = this.provider().getSigner()
        const gasPrice = await changeGasIncrease(this.provider())
        const tx = await setBalanceWhen(signer, this.balanceWhen, gasPrice)
        const result = await this.provider().waitForTransaction(tx.hash, 1, 1000 * 60)

        this.title = this.$t('confirming transaction, please wait a little longer')

        await this.verifyTransaction(result.status)
      } catch (e) {
        if (['ACTION_REJECTED'].includes(e.code)) {
          this.toast(this.$t('operation canceled by user'), 'warning', 'Failed')
        } else {
          this.toast(this.$t('your transaction failed, see more at metamask'), 'warning', 'Failed')
        }
        this.balanceWhen = (await getPosition(this.provider().getSigner(), this.getAccount)).balanceWhen
      }
      this.request = false
      this.$bvModal.hide('modal-processing')
      this.title = this.$t('Processing')
    },

    async approve(item) {
      this.$bvModal.show('modal-processing')
      this.request = true
      const limitValue = 50000000

      try {
        const signer = this.provider().getSigner()
        const tokenAddress = item.token
        const amount = limitValue / item.price
        const gasPrice = await changeGasIncrease(this.provider())

        const tx = await ERC20Approve(signer, tokenAddress, amount, gasPrice)
        // const { hash } = tx
        const result = await this.provider().waitForTransaction(tx.hash, 1, 1000 * 60)
        // `https://polygonscan.com/tx/${hash}`

        await this.verifyTransaction(result.status)
        item.needApproval = false
      } catch (e) {
        if (['ACTION_REJECTED'].includes(e.code)) {
          this.toast(this.$t('operation canceled by user'), 'warning', 'Failed')
        } else {
          this.toast(this.$t('your transaction failed, see more at metamask'), 'warning', 'Failed')
        }
      }
      this.request = false
      this.$bvModal.hide('modal-processing')
    },

    async editTokens() {
      this.$bvModal.show('modal-edit-wallet')
    },

    modalProcessingClose(evt) {
      if (this.request === true) {
        evt.preventDefault()
        this.toast(this.$t('NotCreated.waitMessage'))
      }
    },

    async awaitForChange() {
      while (true) {
        await sleep(3)
        const position = await getPosition(this.provider().getSigner(), this.getAccount)

        const cond1 = this.position.tokens.length !== position.tokens.length
        const cond2 = this.position.isAutomated !== position.isAutomated
        const cond3 = this.position.balanceWhen !== position.balanceWhen
        let cond4 = false

        if (!cond1) {
          for (let key = 0; key < position.tokens.length; key += 1) {
            if (position.tokens[key].amount !== this.position.tokens[key].amount || position.tokens[key].percentage !== this.position.tokens[key].percentage) {
              cond4 = true
              break
            }
          }
        }

        if (cond1 || cond2 || cond3 || cond4) {
          this.setPosition(position)
          break
        }
      }
    },
  },
}
</script>

<style lang="scss">
#modal-processing .modal-header .close {
  display:none;
}
</style>
