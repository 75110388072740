<template>
  <b-card v-if="showCharts">
    <!-- confirm transaction -->
    <b-modal
      id="modal-processing-pie"
      centered
      show
      size="sm"
      :title="title"
      hide-footer
      @hide="modalProcessingClose"
    >
      <slot>
        <b-card-text>
          <b-spinner
            variant="success"
            label="Spinning"
          />
        </b-card-text>
      </slot>
      <template #modal-footer />
    </b-modal>

    <div class="card-title">
      {{ $t('Current portfolio') }}

      <b-button
        v-if="disableBalanceNow"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="warning"
        @click="balanceNow()"
      >
        {{ $t("Rebalance now") }}
      </b-button>

    </div>
    <div class="pie-text text-center">
      <h2 class="font-weight-bolder">
        ${{ sumValue }}
      </h2>
      <span class="font-weight-bold">{{ $t('Total') }}</span>
    </div>

    <!-- echart -->
    <app-echart-doughnut :series="series" />

  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BCard, BButton, VBModal, BCardText, BSpinner,
} from 'bootstrap-vue'
import AppEchartDoughnut from '@core/components/charts/echart/AppEchartDoughnut.vue'
import Ripple from 'vue-ripple-directive'
import { balancerRun } from '@/services/balancer'
import {
  changeGasIncrease, sleep,
} from '@/libs/helpers'
import { getPosition, getSnap } from '@/services'

export default {
  components: {
    BCard,
    BCardText,
    BSpinner,
    AppEchartDoughnut,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      request: false,
      title: this.$t('Processing'),
    }
  },
  computed: {
    ...mapGetters({
      position: 'balancer/position',
      getAccount: 'wallet/getAccount',
      showCharts: 'balancer/showCharts',
      snap: 'balancer/snap',
    }),
    sumValue() {
      return this.position.sumValue
    },
    disableBalanceNow() {
      return !this.position.tokens.filter(p => p.needApproval).length
    },
    series() {
      return [
        {
          name: 'Visit source',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: true,
          label: {
            show: true,
          },
          labelLine: {
            show: true,
          },
          data: this.position.tokens.map(t => ({ value: t.amount * t.price, name: `${t.symbol} - (${t.amount})` })),
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      setPosition: 'balancer/setPosition',
      setSnap: 'balancer/setSnap',
    }),

    toast(message, variant = 'warning', title = this.$t('NotCreated.wait')) {
      this.$bvToast.toast(message, {
        title,
        toaster: 'b-toaster-top-right',
        variant,
        solid: true,
        appendToast: true,
      })
    },

    modalProcessingClose(evt) {
      if (this.request === true) {
        evt.preventDefault()
        this.toast(this.$t('NotCreated.waitMessage'))
      }
    },

    async verifyTransaction(status) {
      switch (status) {
        case 0:
          this.toast(this.$t('your transaction failed, see more at metamask'), 'warning', 'Failed')
          break
        default:
          this.toast(this.$t('successful transaction'), 'success', 'OK')
          await this.awaitForChange()
      }
    },

    async balanceNow() {
      console.info('balanceNow')
      this.$bvModal.show('modal-processing-pie')
      this.request = true

      try {
        const signer = this.provider().getSigner()
        const gasPrice = await changeGasIncrease(this.provider(), 50)
        const params = [
          { token: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', fee: 500 },
          { token: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6', fee: 500 },
          { token: '0xd6df932a45c0f255f85145f286ea0b292b21c90b', fee: 500 },
          { token: '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39', fee: 500 },
          { token: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', fee: 500 },
        ]

        const tx = await balancerRun(signer, params, gasPrice)
        const result = await this.provider().waitForTransaction(tx.hash, 1, 1000 * 60)
        // `https://polygonscan.com/tx/${hash}`
        this.title = this.$t('confirming transaction, please wait a little longer')
        await this.verifyTransaction(result.status)
      } catch (e) {
        console.info(e)
        if (['ACTION_REJECTED'].includes(e.code)) {
          this.toast(this.$t('operation canceled by user'), 'warning', 'Failed')
        } else {
          this.toast(this.$t('your transaction failed, see more at metamask'), 'warning', 'Failed')
        }
      }
      this.request = false
      this.$bvModal.hide('modal-processing-pie')
    },

    async awaitForChange() {
      while (true) {
        await sleep(3)
        const position = await getPosition(this.provider().getSigner(), this.getAccount)

        if (this.position.tokens[0].amount !== position.tokens[0].amount) {
          this.setPosition(position)
          this.setSnap(await getSnap(this.provider().getSigner(), this.getAccount))
          break
        }
      }
    },
  },
}
</script>

<style lang="scss">
.card-body{
  position: relative;
  .pie-text{
      width: 105px;
      position:absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 44%;
      bottom: 0;
    }
  }

  #modal-processing-pie .modal-header .close {
  display:none;
}
</style>
