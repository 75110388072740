<template>
  <b-card :title="title">
    <!-- confirm transaction -->
    <b-modal
      v-if="!saveDisable"
      id="modal-save"
      centered
      show
      :title="titleModal"
      :hide-footer="request"
      ok-only
      :ok-title="$t(`NotCreated.Confirm`)"
      @hide="modalSaveClose"
    >
      <slot>
        <b-card-text v-if="!request">
          <h5>
            {{ $t(`NotCreated.Tokens`) }}
          </h5>

          <b-table
            responsive="sm"
            :items="itemsModal"
          />
        </b-card-text>
        <b-card-text v-else>
          <b-spinner
            style="width: 3rem; height: 3rem;"
            label="Large Spinner"
            type="grow"
          />
        </b-card-text>
      </slot>
      <template #modal-footer>
        <b-button
          :disabled="request"
          variant="success"
          @click="handleConfirmOk"
        >
          {{ $t(`NotCreated.Confirm`) }}
        </b-button>

      </template>
    </b-modal>

    <b-row>
      <b-col md="7">
        <div>
          <div>
            <b-form
              ref="form"
              :style="{height: trHeight}"
              class="repeater-form"
              @submit.prevent="repeatAgain"
            >
              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in items"
                :id="item.id"
                :key="item.id"
                ref="row"
              >

                <!-- Token -->
                <b-col md="7">
                  <b-form-group
                    label="Token"
                    label-for="token"
                  >
                    <v-select
                      :key="item.id"
                      v-model="form.token[index]"
                      label="title"
                      :options="tokens"
                    />
                  </b-form-group>
                </b-col>

                <!-- Percentage -->
                <b-col md="3">
                  <b-form-group
                    label="Percentage"
                    label-for="percentage"
                  >
                    <cleave
                      id="percentage"
                      :key="item.id"
                      v-model="form.percentage[index]"
                      class="form-control"
                      maxlength="5"
                      :options="options.percentage"
                      placeholder="%"
                    />
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col
                  lg="2"
                  md="2"
                  class="mb-50"
                >
                  <b-button
                    v-ripple.200="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span />
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>

            </b-form>
          </div>
          <div>
            <b-row>

              <b-col
                md="12"
              >
                <div class="mb-2">
                  <b-progress
                    :value="sumPercentage"
                    max="100"
                    show-progress
                    :variant="variantProgress"
                    :class="{
                      'progress-bar-warning': sumPercentage < 100,
                      'progress-bar-success': sumPercentage === 100,
                      'progress-bar-danger': sumPercentage > 100,

                    }"
                  >
                    <b-progress-bar
                      :value="sumPercentage"
                    >
                      <strong>{{ sumPercentage }}%</strong>
                    </b-progress-bar>
                  </b-progress>

                </div>
              </b-col>

              <b-col md="7">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1 mb-1"
                  variant="primary"
                  :disabled="addTokenDisable"
                  @click="repeatAgain"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span> {{ $t("Add Token") }} </span>
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1 mb-1"
                  variant="success"
                  :disabled="saveDisable"
                  @click="openModal()"
                >
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-25"
                  />
                  <span> {{ $t("Save") }} </span>
                </b-button>

              </b-col>

              <b-col
                md="5"
                lg="5"
              />

            </b-row>

          </div>
        </div>
      </b-col>
      <b-col md="5">
        <b-alert
          variant="warning"
          show
        >
          <h4 class="alert-heading">
            {{ $t("NotCreated.information") }}
          </h4>
          <div class="alert-body">
            <p class="mt-1">
              {{ $t("NotCreated.p1") }}
            </p>
            <p class="mt-2">
              {{ $t("NotCreated.p2") }}
            </p>
            <p class="mt-2">
              {{ $t("NotCreated.p3") }}
            </p>
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BForm, BFormGroup, BRow, BCol, BButton, BCard, BAlert, BProgress, BProgressBar, BModal, VBModal, BCardText, BSpinner, BTable,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import {
  formatPercentage, itemPosition, sendPosition, changeGasIncrease, sleep,
} from '@/libs/helpers'

import { getPosition } from '@/services'

export default {
  components: {
    BTable,
    BProgress,
    BProgressBar,
    Cleave,
    vSelect,
    BCardText,
    BSpinner,
    BAlert,
    BCol,
    BForm,
    BRow,
    BButton,
    BFormGroup,
    BCard,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    title: {
      type: String,
      default: '',
    },
    account: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      titleModal: this.$t('NotCreated.confirm transaction'),
      timeInterval: 0,
      options: {
        percentage: {
          numericOnly: true,
          blocks: [2, 2],
          delimiters: ['.'],
        },
      },
      form: {
        token: [],
        percentage: [],
      },
      items: [],
      nextTodoId: 1,
      request: false,
      hideModalText: false,
      transaction: this.resetTransaction(),
    }
  },
  computed: {
    ...mapGetters({
      tokensList: 'balancer/tokens',
      position: 'balancer/position',
      getAccount: 'wallet/getAccount',
    }),
    tokens() {
      return this.tokensList
        .filter(tl => !this.form.token.filter(t => t !== null).map(t => t.address).includes(tl.address || ''))
    },
    sumPercentage() {
      return this.form.percentage.map(p => (p.trim().length ? formatPercentage(p.trim()) : 0)).reduce((p, c) => p + c, 0)
    },
    addTokenDisable() {
      return this.items.length >= this.tokensList.length
    },
    saveDisable() {
      return this.sumPercentage !== 100
        || this.items.length !== this.form.token.filter(t => t !== null).length
        || this.items.length !== this.form.percentage.filter(t => t.trim() !== '' && t > 0).length
    },
    variantProgress() {
      if (this.sumPercentage < 100) return 'warning'
      if (this.sumPercentage === 100) return 'success'
      return 'danger'
    },

    itemsModal() {
      const rows = []
      for (let index = 0; index < this.items.length; index += 1) {
        rows.push({
          token: this.form.token[index].title,
          percentage: `${formatPercentage(this.form.percentage[index])}%`,
        })
      }
      return rows
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
    if (this.timeInterval > 0) clearInterval(this.timeInterval)
  },
  methods: {
    ...mapActions({
      setPosition: 'balancer/setPosition',
    }),
    resetTransaction() {
      return {
        show: false,
        message: this.$t('NotCreated.wait'),
        link: '',
      }
    },

    openModal() {
      this.resetTransaction()
      this.$bvModal.show('modal-save')
    },

    repeatAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },

    removeItem(index) {
      this.items.splice(index, 1)
      this.form.token.splice(index, 1)
      this.form.percentage.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },

    toast(message, variant = 'warning', title = this.$t('transaction')) {
      this.$bvToast.toast(message, {
        title,
        toaster: 'b-toaster-top-right',
        variant,
        solid: true,
        appendToast: true,
      })
    },

    async handleConfirmOk(evt) {
      evt.preventDefault()
      this.request = true

      // init transaction
      const tokens = []
      for (let key = 0; key < this.items.length; key += 1) {
        tokens.push(itemPosition(
          this.form.token[key].address,
          this.form.token[key].chainLinkFeed,
          formatPercentage(this.form.percentage[key]),
        ))
      }

      this.transaction = this.resetTransaction()
      this.titleModal = this.$t('Processing')
      // send transaction
      let hash = ''
      try {
        const gasPrice = await changeGasIncrease(this.provider())
        const tx = await sendPosition(this.provider().getSigner(), tokens, gasPrice)
        hash = tx.hash

        this.transaction.message = this.$t('NotCreated.waitMessage')

        const result = await this.provider().waitForTransaction(tx.hash, 1, 1000 * 60)
        this.transaction.link = `https://polygonscan.com/tx/${hash}`

        await this.verifyTransaction(result.status)
      } catch (e) {
        if (['ACTION_REJECTED'].includes(e.code)) {
          this.toast(this.$t('operation canceled by user'))
        } else {
          this.toast(this.$t('your transaction failed, see more at metamask'), 'warning', 'Failed')
        }
      }

      this.request = false
      this.hideModalText = false
      this.titleModal = this.$t('NotCreated.confirm transaction')
      this.transaction.message = this.$t('NotCreated.wait')
    },

    async verifyTransaction(status) {
      switch (status) {
        case 0:
          this.toast(this.$t('your transaction failed, see more at metamask'), 'warning', 'Failed')
          break
        default:
          this.toast(this.$t('successful transaction'), 'success', 'OK')
          this.titleModal = this.$t('confirming transaction, please wait a little longer')
          this.hideModalText = true
          await this.awaitForChange()
      }
    },

    async awaitForChange() {
      while (true) {
        await sleep(3)
        const position = await getPosition(this.provider().getSigner(), this.getAccount)

        if (this.position.isCreated !== position.isCreated) {
          this.setPosition(position)
          break
        }
      }
    },

    modalSaveClose(evt) {
      if (this.request === true) {
        evt.preventDefault()
        this.toast(this.$t('NotCreated.waitMessage'))
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
