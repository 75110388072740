import * as ethers from 'ethers'
import { BigNumber } from 'ethers'
import balancer from '@core/abi/balancer'

export const contractBalancerCreate = signer => new ethers.Contract(balancer.address, balancer.abi, signer)

export const balancerRun = async (signer, params, gasPrice) => {
  const contract = contractBalancerCreate(signer)

  return contract.run(params, {
    gasPrice,
    gasLimit: BigNumber.from('1600000'),
    nonce: await signer.getTransactionCount(signer.address),
  })
}

export const setAutomatic = async (signer, value, gasPrice) => {
  const contract = contractBalancerCreate(signer)

  return contract.setAutomatic(value, {
    gasPrice,
    gasLimit: BigNumber.from('800000'),
    nonce: await signer.getTransactionCount(signer.address),
  })
}

export const setBalanceWhen = async (signer, _value, gasPrice) => {
  const contract = contractBalancerCreate(signer)
  const value = ethers.utils.parseUnits(_value.toString(), 2)

  return contract.setAutomatic(value, {
    gasPrice,
    gasLimit: BigNumber.from('800000'),
    nonce: await signer.getTransactionCount(signer.address),
  })
}

export default {
  contractBalancerCreate,
  balancerRun,
  setAutomatic,
  setBalanceWhen,
}
